import './App.scss';
import ContactForm from './Components/Home/ContactForm';
import Logos from './Components/Home/Logos';
import Footer from './Components/Home/Footer';
import { Container, Row } from 'react-bootstrap';

function App() {
  return (
    <div className="full-height d-flex justify-content-center align-items-center px-md-5 px-sm-3 px-3">
      <Container fluid>
        <Row>
          <ContactForm />
          <Logos />
          <Footer></Footer>
        </Row>
        </Container>
    </div>
  );
}

export default App;
