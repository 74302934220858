import { Row, Col, Image } from 'react-bootstrap';
import gpb from '../../assets/images/gpb.png';
import credo from '../../assets/images/credo.png';
import radius from '../../assets/images/radius.png';
import rebank from '../../assets/images/rebank.png';
import orbeliani from '../../assets/images/orbeliani.png';
// import meti from '../../assets/images/meti.png';
// import impact from '../../assets/images/impact.png';
import primetime from '../../assets/images/primetime.png';

function Logos () {
    return (
      <Col md={7} className="logos">
        <Row>
          <Col xs={6} sm={6} md={4} className="logos__item m-0 p-0 rounded-0 border-0">
          <Image src={ gpb } className="m-0 p-0 rounded-0 border-0" thumbnail style={{ backgroundColor: '#20020a', objectFit: 'cover'}} />
          </Col>
          <Col xs={6} sm={6} md={4} className="logos__item m-0 p-0 rounded-0 border-0">
          <Image src={ credo } className="m-0 p-0 rounded-0 border-0" thumbnail style={{ backgroundColor: '#153e56'}} />
          </Col>
          <Col xs={6} sm={6} md={4} className="logos__item m-0 p-0 rounded-0 border-0">
          <Image src={ radius } className="m-0 p-0 rounded-0 border-0" thumbnail style={{ backgroundColor: '#0b7375'}} />
          </Col>
          <Col xs={6} sm={6} md={4} className="logos__item m-0 p-0 rounded-0 border-0">
          <Image src={ rebank } className="m-0 p-0 rounded-0 border-0" thumbnail style={{ backgroundColor: '#16274a'}} />
          </Col>
          <Col xs={6} sm={6} md={4} className="logos__item m-0 p-0 rounded-0 border-0">
            <Image src={ orbeliani } className="m-0 p-0 rounded-0 border-0" thumbnail style={{ backgroundColor: '#0047b2'}} />
          </Col>
          <Col xs={6} sm={6} md={4} className="logos__item m-0 p-0 rounded-0 border-0">
            <Image src={ primetime } className="m-0 p-0 rounded-0 border-0" thumbnail style={{ backgroundColor: '#ec1c24'}} />
          </Col>
        </Row>
      </Col>
    );
}

export default Logos;