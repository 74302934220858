import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      isLoading: false,
    };

    this.sendMessage = this.sendMessage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

  }

  handleInputChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    });
  }
  
  sendMessage(e) {
    e.preventDefault();
    this.setState({isLoading: true});
    console.log(this.state);
  }
  
  render() {
    // const { name, email, message, isLoading } = this.state;
    return (
      <Col md={5}>
        <Row style={{ height: 100+'%'}}>
          {/* <Form className="contact">
            <h1 className="contact__title"> Contact Us </h1>
            <Form.Group controlId="contactFieldName">
              <Form.Label>name</Form.Label>
              <Form.Control type="text" placeholder="Name" name="name" onChange={this.handleInputChange} value={ name } />
            </Form.Group>
            <Form.Group controlId="contactFieldMail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" name="email" onChange={this.handleInputChange} value={ email } />
            </Form.Group>
            <Form.Group controlId="contactFieldMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" onChange={this.handleInputChange} value={ message } />
            </Form.Group>
            <Button variant="secondary" type="submit" disabled={isLoading} onClick={this.sendMessage}>
              {isLoading ? <Spinner animation="grow" variant="primary" size="sm" /> : 'Send'}
            </Button>
          </Form> */}
          <Col style={{display: 'flex', alignItems: 'center'}}>
            <div className="logos">
              <div className="logos__symbiosis">
                <h1 className="logos__symbiosis__title">
                  SYMBIOSIS
                </h1>
                <span>Coming Soon</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default ContactForm;