function Footer () {
    return (
        <div className="logos__contact">
            <div style={{marginRight: 15}}>
                <label htmlFor="email">Email: </label>
                <a name="email" href="mailto:hello@symbiosis.ge">hello@symbiosis.ge</a>
            </div>
            <div>
                <label htmlFor="phone">Phone: </label>
                <a name="phone" href="tel:+995598344060">+995 598 34 40 60 &nbsp;</a>
            </div>
        </div>
    );
}

export default Footer;